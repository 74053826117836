import { useCallback, useMemo, useState } from "react";
import { config } from "../../../../../config";
import { SwitzerlandTreatment } from "../types";

export type AddedTreatment = Record<
  string,
  {
    urn: string;
    name: string;
    quantity: number;
    price: number;
  }
>;

type UseHandleTreatments = (params: { treatments: SwitzerlandTreatment[] }) => {
  addedTreatments: AddedTreatment;
  handleAddTreatment(treatmentUrn: string): void;
  handleRemoveTreatment(treatmentUrn: string): void;
  redirectToFormWithDetails(): void;
};

export const useHandleTreatments: UseHandleTreatments = ({ treatments }) => {
  const [addedTreatments, setAddedTreatments] = useState<AddedTreatment>({});
  const treatmentsMap = useMemo(
    () => Object.fromEntries(treatments.map((entry) => [entry.urn, entry])),
    [treatments],
  );

  const handleAddTreatment = useCallback(
    (treatmentUrn: string): void => {
      const treatmentEntry = treatmentsMap[treatmentUrn];

      if (!treatmentEntry) {
        return;
      }

      setAddedTreatments((currTreatments) => {
        if (currTreatments) {
          const existingTreatment = {}.hasOwnProperty.call(currTreatments, treatmentUrn)

          if (existingTreatment) {
            currTreatments[treatmentUrn].quantity += 1;
            return { ...currTreatments };
          } else {
            return {
              ...currTreatments,
              [treatmentUrn]: {
                urn: treatmentUrn,
                name: treatmentEntry.name,
                quantity: 1,
                price: treatmentEntry.price.classic,
              },
            };
          }
        }

        return {};
      });
    },
    [treatmentsMap],
  );

  const handleRemoveTreatment = useCallback((treatmentUrn: string): void => {
    setAddedTreatments((currTreatments) => {
      if (currTreatments) {
        const existingTreatment = {}.hasOwnProperty.call(
          currTreatments,
          treatmentUrn,
        );

        if (!existingTreatment) {
          return currTreatments;
        } else if (currTreatments[treatmentUrn].quantity > 1) {
          currTreatments[treatmentUrn].quantity -= 1;
          return { ...currTreatments };
        } else {
          delete currTreatments[treatmentUrn];
          return { ...currTreatments };
        }
      } else {
        return {};
      }
    });
  }, []);

  const transformToQueryString = useCallback(
    (treatments: AddedTreatment[keyof AddedTreatment][]): string => {
      return treatments
        .map(({ urn, quantity }) => `${urn.split("-").pop()}=${quantity}`)
        .join("&");
    },
    [],
  );

  const redirectToFormWithDetails = useCallback(() => {
    const queryString = transformToQueryString(Object.values(addedTreatments));
    const url = `${config.unbounceSwitzFormUrl}?${queryString}`;
    window.open(url, "_blank");
  }, [addedTreatments, transformToQueryString]);



  return {
    addedTreatments,
    handleAddTreatment,
    handleRemoveTreatment,
    redirectToFormWithDetails,
  };
};
